<template>
  <div class="card rounded shadow">
    <div class="card-body">
      <h5 class="card-title text-uppercase d-flex flex-row flex-wrap align-items-center justify-content-start">
        Start Earning With Our Referral Program
      </h5>
      <p style="max-width: 600px!important;" class="d-flex flex-wrap align-items-center justify-content-start" v-if="user.referral">
        Earn by referring your friends to place orders with our website. For
        every successful referral we will credit both your account with {{ user.referral.bonus || "" }}
        dollars each. Invite your friends using your referral code. Your
        referral code is
        <strong>{{ user.referral.link || "" }}</strong> . Earn your
        <strong>${{ user.referral.bonus || "" }} dollars</strong> now
      </p>
    

      <div class="d-flex flex-row flex-wrap align-items-center justify-content-start" >
        <a  :href="`https://twitter.com/share?text=Hello+buddy%2CI+found+this+fabulous+custom+writing+website.+Its+all+about+helping+students+with+their+complex+school+work+as+you+learn+how+to+write+your+own+A+grade+papers.%3Ca+href%3D%27${siteLink}%3Fid%3D16432%3Eregister%3C%2Fa%3E&amp;url=${siteLink}`" target="blank" class="m-2" data-v-0e5bd61f="">
          <div class="btn m-1 d-flex align-items-center justify-content-center flex-column mx-auto p-3 rounded" style="background-color: rgb(29, 161, 243); color: white;" data-v-0e5bd61f="twitter">
            <!-- <img src="/orders/icons8-twitter.svg" alt=""> -->
             Invite on Twitter 
          </div>
          </a>
          <a  :href="`https://www.facebook.com/sharer.php?u=${siteLink}`" target="blank" class="m-2" data-v-0e5bd61f=""><div class="btn m-2 d-flex align-items-center justify-content-center flex-column mx-auto p-3 rounded" style="background-color: rgb(59, 89, 152); color: white;" data-v-0e5bd61f="">
            <i class="fa-brands fa-2xl fa-facebook" data-v-0e5bd61f=""></i> Invite on Facebook 
          </div>
        </a>
        <a :href="`mailto:?Subject=${siteLink} Referral Program&amp;body=Hello buddy%2CI found this fabulous custom writing website. Its all about helping students with their complex school work as you learn how to write your own A grade papers. Check it out ${siteLink}`" target="blank" class="m-2" data-v-0e5bd61f="">
          <div class="btn m-1 d-flex align-items-center justify-content-center flex-column mx-auto p-3 rounded" style="background-color: rgb(255, 67, 67); color: white;" data-v-0e5bd61f=""><i class="fa-solid fa-2xl fa-envelope" data-v-0e5bd61f=""></i> Invite on Email 
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BaseShare",
  computed: {
    ...mapState("auth", ["user"]),
    siteLink() {
      return this.user.referral.link || {};
    },
  },
};
</script>